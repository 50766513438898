




import Vue from 'vue';
import { vuetifyComponents } from '@/plugins/vuetify';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'purchase-order-banner',
  components: {
    ...vuetifyComponents,
  },
  computed: {
    ...mapState({
      purchaseOrder: (state: any) => state.project.project,
    }),
  },
  methods: {
    // Purchase order details
    isPODetailsDisabled() {
      return this.disabledTabs.includes(1);
    },
    isPODetailsSelected() {
      return this.activeTab === 1;
    },
    // Products tab
    isProductsDisabled() {
      return this.disabledTabs.includes(2);
    },
    isProductsSelected() {
      return this.activeTab === 2;
    },
    // Models to review
    isModelsToReviewDisabled() {
      return true;
    },
    isModelsToReviewSelected() {
      return this.activeTab === 3;
    },
    // Final assets
    isFinalAssetsDisabled() {
      return true;
    },
    isFinalAssetsSelected() {
      return this.activeTab === 4;
    },
  },
  props: {
    activeTab: Number,
    loading: Boolean,
    disabledTabs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
});
